<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="margin-bottom-10 top">
        <Button type="primary" @click="add">{{$t('system_permission_add')}}</Button>
        <div class="search_input">
          <Input v-model="keyword"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
                 :placeholder="$t('system_permission_search_tip')"
                 clearable
                 search
                 :enter-button="true"
          />
        </div>
      </div>

      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('system_permission_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <Modal
          v-model="modalStatus"
          :title="modalTit"
          @on-cancel="cancel"
      >
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem :label="$t('system_permission_table_name')" prop="name">
            <Input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('system_permission_add_name_tip')"></Input>
          </FormItem>
          <FormItem :label="$t('system_permission_table_desc')" prop="desc">
            <Input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('system_permission_add_desc_tip')"></Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="cancel">取消</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>
        </div>
      </Modal>
    </div>
  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: this.$t('system_permission_table_index'),
          // key: 'index',
          type:'index'
        },
        {
          title: this.$t('system_permission_table_name'),
          key: 'name',
        },
        {
          title: this.$t('system_permission_table_desc'),
          key: 'description',
        },
        {
          title: this.$t('system_permission_table_op'),
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.check(params.row)
                  }
                }
              }, this.$t('system_check')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, this.$t('system_edit')),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, this.$t('system_dele'))
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      keyword: '',
      modalStatus:false,
      formItem:{
        name:'',
        desc:''
      },
      ruleValidate: {
        name: [
          { required: true, message: this.$t('system_permission_add_name_tip'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('system_permission_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      oldName:'',
      modalTit:'',
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getPermission();
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    }
  },
  methods:{
    changeKeyword () {
      this.page = 1;
      this.getPermission();
    },
    add(){
      this.modalTit = this.$t('system_permission_add');
      this.type = '';
      this.oldName = '';
      this.formItem = {
        name:'',
        desc:''
      };
      this.modalStatus = true;
    },
    check(data){
      this.modalTit = this.$t('system_permission_check');
      this.type = 'check';
      this.oldName = '';
      this.formItem = {
        name:data.name,
        desc:data.description
      };
      this.modalStatus = true;
    },
    edit(data){
      this.modalTit = this.$t('system_permission_edit');
      this.type = 'edit';
      this.oldName = data.name;
      this.formItem = {
        name:data.name,
        desc:data.description
      };
      this.modalStatus = true;
    },
    getPermission(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
      };
      this.api.system.permissions(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getPermission();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        name:this.curDeleData.name
      }
      this.modalLoading = true;
      this.api.system.permissionDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getPermission();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getPermission();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getUserList();
    },
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            name:this.formItem.name,
            description:this.formItem.desc
          };
          this.addBtnLoading = true;
          if(this.oldName){
            data.name_old = this.oldName;
            this.api.system.permissionUpdate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getPermission();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.system.permissionCreate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getPermission();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }

        }else{
          console.log(this.modalStatus)
        }
      })
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
